import React from 'react' 
import "./App.css";
import './assets/styles/style.scss'
import LayoutContainer from './layoutcontainer';
import { Provider as ReduxProvider } from 'react-redux';
import Store from '../src/redux/store/store';
// import PageNotFound from '../src/sharedComponnents/page-not-found/PageNotFound';
// import getDeviceToken from './device-token/DeviceToken';

function App() {
  return (
    <ReduxProvider store={Store}>
    <div className="App">
      {/* <PageNotFound> */}
        <LayoutContainer />
      {/* </PageNotFound> */}
    </div>
    </ReduxProvider>
  );
}

export default App;
