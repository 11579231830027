// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

import "@firebase/messaging";
import { BehaviorSubject } from "rxjs";

var firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

export const deveiceToken = new BehaviorSubject(``) ;
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

export default function getDeviceToken() {
  if (!firebase.messaging.isSupported()) {
    return;
  }
  const FirebaseMessaging = firebase.messaging();

  Notification.requestPermission()
    .then(() => {
      return FirebaseMessaging.getToken({
        vapidKey:
          "BCAU1OqFiLB_gQJA5V2pw_Pf1PHgsrxtaA-v4uDhH8tQgnaUoeYK4MDqvcKIverz6bRj6dgCu_uC4ovmlmCGH3Y",
      });
    })
    .then((token) => {
      // console.log("HAVEEEEEEEE" , token);
      deveiceToken.next(token);
      localStorage.setItem("deviceToken", token);
    })
    .catch((err) => {
      console.log("@@@@@@@@@@@@@@@@@messaging error", err);
      return
    });

  FirebaseMessaging.onMessage((msg) => {
    // console.log("@@@@@1234555", msg);
    // if (msg?.data?.twilioToken) {
    //   localStorage.setItem("callerDetails", JSON.stringify(msg.data));
    //   history.push("/my-appointments");
    //   // history.push(
    //   //   `/video-call/${JSON.stringify(msg.data)}/${msg.data.pushType}`
    //   // );
    //   // window.location.reload();
    // } else if (msg?.data?.type === "call_reject") {
    //   history.push("/my-appointments");
    //   // window.location.reload();
    // }
  });
}
