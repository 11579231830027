import React, { Component } from 'react';
import './footer.scss';
import Logo from '../../assets/img/logo-white.png';
import LogoBlue from '../../assets/img/brand-color-removebg-preview.png';
// import LogoBlue from '../../assets/img/footer-logo.png';
// import FbIc from '../../assets/img/fb.png';
// import InstagramIc from '../../assets/img/instagram.png';
import PlayStoreIc from '../../assets/img/play-store.png';
import IosIc from '../../assets/img/apple.png';
import { ReactComponent as FbIc } from '../../assets/img/facebook-f.svg';
import { ReactComponent as TwitterIc } from '../../assets/img/twitter.svg';
import { ReactComponent as YoutubeIc } from '../../assets/img/youtube.svg';
import { ReactComponent as LinkedinIc } from '../../assets/img/linkedin.svg';
import { Link } from 'react-router-dom';
import { LAWYER_TERM_AND_CONDITIONS, CLIENT_TERMS_AND_CONDITIONS, CLIENT_PRIVACY_POLICY, FAQ, NOTIFICATIONS, CHANGE_PASSWORD, } from '../../config/RouterConfig';
import { useSelector } from 'react-redux';
import Users from '../../Services/users';


const Footer = () => {
    const user = new Users();
    const authReducer = useSelector(state => state.AuthReducer);
    // console.log('authReducer', authReducer);
    const userType = localStorage.getItem('userType')



    return (
        <div className="footer client_footer">
            <div className="container">
                <div className="flex">
                    <div className="item">
                        <div className="footer_logo">
                            <figure>
                                <img src={LogoBlue} className="img-fluid" alt="" />
                            </figure>
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p> */}

                        </div>

                    </div>
                    <div className="item">
                        <h4>Contact</h4>
                        <div className="footer-menu">
                            <a>General enquiries</a>
                            <a>Marketing enquiries</a>
                            <a>Media enquiries</a>
                            <a>Feedback and complaints</a>
                        </div>
                    </div>
                    <div className="item">
                        <h4>Legal</h4>
                        <div className="footer-menu">
                            <a href="/privacy-policy" >Privacy policy </a>
                            <a>Disclaimer</a>
                        </div>
                    </div>
                    <div className="item">
                        <h4>We're here to help</h4>
                        <p>if you have a question, get in touch with our team.</p>
                        <div className="footer-menu">
                            <a className='btn-make-enquire'>Make an enquiry</a>
                            <a className='btn-call' href='tel:1800 3214 758'>Call us on 1800 3214 758</a>
                        </div>
                    </div>

                </div>
            </div>
            <div className='container'>
                <div className="copy-right">
                    <div className='flex'>
                        <div className='copyRight_content'>
                            <p>Copyright © <span><b>Swiftlaw</b></span>, 2021. All rights reserved*.</p>
                            <p><i>*Except as permitted by the copyright law applicable to you, you may not copy, adapt, use, reproduce, communicate or commercialise any of the content on this website without the prior written permission of the copyright owner.</i></p>
                        </div>
                        <div className='socialMedia'>
                            <a href="https://www.facebook.com"><FbIc /></a>
                            <a href="https://twitter.com/i/flow/login"><TwitterIc /></a>
                            <a href="https://www.youtube.com"><YoutubeIc /></a>
                            <a href="https://www.instagram.com/"><LinkedinIc /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Footer;