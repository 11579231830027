export const LOGIN = "LOGIN";
export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const LOGOUT = "LOGOUT";
export const ID_PROFILE = "ID_PROFILE";
export const USERID = "USERID";
export const IDTYPE = "IDTYPE";
export const USER_DATA = "USER_DATA";

export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const URL = "URL";