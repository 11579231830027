import axios from 'axios';

export const APIURL = process.env.REACT_APP_SWIFTLAW_BASE_URL // Umair sir base url  https://api.swiftlaw.com.au/api/
// export const APIURL = "http://localhost:4002/api/" // Umair sir base url


export const instance = axios.create({
  baseURL: process.env.REACT_APP_SWIFTLAW_BASE_URL ,
  headers: {
    'Content-Type': "application/json",
    'token': localStorage.getItem('token')
  }
});

export const ApisEndpt = {
  // Common File // upload File 
  countryStateList: "v1/common/countryStateList",
  stateCityList: "v1/common/stateCityList",
  uploadFile: "v1/common/uploadFile",
  serviceList: "v1/lawyer/serviceList",
  "check_existency": "v1/common/check_existency",
  viewInterval: "v1/interval/viewInterval",

  // lawyer signup api integration 
  signUp: "v1/lawyer/signUp",
  lawyerProfile: 'v1/lawyer/myProfile'

}

