import React, { Component } from 'react';
import RouterManagement from '../router-management'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class LayoutContainer extends Component {
   
   
    render() {
        return (
            <div className="sl-webapp">
               <ToastContainer />
                {/* <FloatingDraggablePopup/> */}
                <RouterManagement/>
            </div>
        )
    }
}



export default  LayoutContainer
