import React, { Component } from "react";
import axios from "axios";
import * as Constants from "../config/config";
import swal from "sweetalert";
import ToasterFunc from "../sharedComponnents/toastr/Toastr";

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data;

    if (response && response.data.responseCode === 401) {
      window.localStorage.clear();
      window.location.pathname = "/";
      window.location.reload();
    }
    return response;
  },
  function (error) {
    console.log("interceptors.error", error);
    console.log("interceptors.response", error.response);

    if (error && error.response && error.response.status === 401) {
      window.localStorage.clear();
      window.location.replace("/") ;
      window.location.reload();
    }
    if (error && error.response && error.response.status === 402) {
      ToasterFunc("error", error.response.data.responseMessage);
    }
    // if (error && error.response && error.response.status === 404) {
    //   ToasterFunc("error", error.response.data.responseMessage);
    // }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default class Users extends Component {
  login(data, cb) {
    axios
      .post(Constants.APIURL + "v1/common/login", data)
      .then((response) => {
        // console.log(response.status);
        if (response.data.responseCode === 200) {
          cb(response);
        } else if (response.data.responseCode === 201) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
      // .catch((error)=>ToasterFunc("error", error))
  }

  socialLogin(data, cb) {
    axios
      .post(Constants.APIURL + "v1/common/socialLogin", data)
      .then((response) => {
        // console.log(response.status);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  singup(data, cb) {
    axios
      .post(Constants.APIURL + "v1/user/signup", data)
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response && error.response.data.responseCode === 409) {
          console.log(error.response.data.responseMessage);
        }
      });
  }
  async connectStripeAcc(data, cb) {
    await axios
      .post(Constants.APIURL + "v1/lawyer/createConnectedAccount", data)
      .then((response) => {
        if (response.status === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response?.data?.err?.code){
          cb({ error: error.response.data.responseMessage , code : error.response?.data?.err?.code });
        }

        if (error.response && error.response.data.responseCode === 409) {
          console.log(error.response.data.responseMessage);
          cb({ error: error.response.data.responseMessage });
        }
        else{
          cb({ error: error.response.data.responseMessage });
        }
      });
  }
  async updateStripeAcc (data , cb ){
   await axios
    .patch(Constants.APIURL + "v1/user/updateConnectedAccount", data)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        cb(response);
      } else {
        cb({ error: response.data.responseMessage });
      }
    }, this)
    .catch((error) => {
      if (error.response && error.response.data.responseCode === 409) {
        console.log(error.response.data.responseMessage);
      }
    });
  }
  async createAccountLink(data, cb) {
    await  axios
      .post(Constants.APIURL + "v1/user/createAccountLinks", data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response && error.response.data.responseCode === 409) {
          console.log(error.response.data.responseMessage);
        }
      });  }

      async connectLoginDashboard (data, cb) {
        await  axios
          .post(Constants.APIURL + "v1/user/createLoginLink", data)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              cb(response);
            }
          }, this)
          .catch((error) => {
            if (error.response && error.response.data.responseCode === 409) {
              console.log(error.response.data.responseMessage);
            }
          }); 
      }

  async verifyIdentityDocument(data , cb ){
      await axios
      .post(Constants.APIURL + "v1/user/fileUpload/identity_document", data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response && error.response.data.responseCode === 409) {
          console.log(error.response.data.responseMessage);
        }
      });
  }

  singupOther(data, cb) {
    axios
      .post(Constants.APIURL + "v1/otherUser/signup", data)
      .then((response) => {
        console.log("ssadasdassda", response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  lawyerSignup(data, cb) {
    axios
      .post(Constants.APIURL + "v1/lawyer/signUp", data)
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => cb({ error: error.response.data.responseMessage }));
  }
 

  verifyOtp(data, cb) {
    axios
      .post(Constants.APIURL + "v1/common/verifyOtp", data)
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        console.log("varify otp error ", JSON.stringify(error.response));
        if (error && error?.response) {
          cb({ error: error.response.data.responseMessage });
        }
      });
  }

  resendOtp(data, cb) {
    axios
      .post(Constants.APIURL + "v1/common/resendOtp", data)
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  forgotPassword(data, cb) {
    axios
      .post(Constants.APIURL + "v1/common/forgotPassword", data)
      .then((response) => {
        console.log("api=====>", response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        console.log("forgotPassword error ", error.response);
        if (error && error.response) {
          cb(error.response);
        }
      });
  }

  addCard(data, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    axios
      .post(Constants.APIURL + "v1/user/addCard", data, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  editCard(data, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    axios
      .put(
        Constants.APIURL + `v1/user/updateCard_detail?cardId=${data.cardId}`,
        data,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getCard(cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    console.log("tokkkeeeeeennnnnn", token, localStorage.getItem("userData"));
    axios
      .get(Constants.APIURL + "v1/user/cardList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => console.log(error.response));
  }

  agencyTypeList(cb) {
    axios
      .get(Constants.APIURL + "v1/otherUser/agencyTypeList")
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => console.log(error));
  }

  updateInvoiceEmail(data, cb) {
    let token =
      localStorage.getItem("userData") &&
        localStorage.getItem("userData") !== undefined ?
      JSON.parse(localStorage.getItem("userData"))?.token : "";
      
    axios
      .put(Constants.APIURL + "v1/otherUser/updateInvoiceEmail", data, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  resetPassword(data, cb) {
    axios
      .post(Constants.APIURL + "v1/common/resetPassword", data)
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getUpcomingAppointment(cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    axios
      .get(Constants.APIURL + "v1/user/upcomingAppointment", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getServiceList(cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    axios
      .get(Constants.APIURL + "v1/user/serviceList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getProfile(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/myProfile", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  updateUserProfile(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .put(Constants.APIURL + "v1/user/updateProfile", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  getLawerList(serviceId, expYear, rating, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    let url = `v1/user/lawyerList?serviceId=${serviceId}&endYear=${expYear.expYearVal}&startYear=${expYear.startYear}`;
    if (rating) {
      url = `v1/user/lawyerList?serviceId=${serviceId}&endYear=${expYear.expYearVal}&rating=${rating}&startYear=${expYear.startYear}`;
    }
    axios
      .get(Constants.APIURL + `${url}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.log(error));
  }

  viewLawyerDetails(userId, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    let url = `v1/user/viewLawyer?userId=${userId}`;
    axios
      .get(Constants.APIURL + `${url}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getLawyerSlot(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    let url = `v1/common/getSlot?userId=${payload.userId}&bookingDate=${payload.bookingDate}`;
    axios
      .get(Constants.APIURL + `${url}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        // console.log("response :::: " , response);
        cb(response);
      }, this)
      .catch((error) => {
        console.log("error :::: " , error.response.data);

        if (error.response && error.response.data) {
          swal("Error", error.response.data.responseMessage);
        }
      });
  }

  createPaymentMethod(data,update,cb) {
    let token =
      localStorage.getItem("userData") ?
      JSON.parse(localStorage.getItem("userData")).token : "";
    axios
      .post(Constants.APIURL + "v1/user/createPaymentMethod", data, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        // console.log(response.status);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        // if (error.response && error.response.data) {
          // swal("Error", error.response.data.responseMessage);
          if ({status: error.response.data.responseCode===501}) {
            swal("Error", "Please enter the valid card number").then(update());
            // swal({text: "Please enter the valid card number",type:"ok"}).then(update());
          }
        // }
      });
  }

  transferLawyerPayment(data, cb) {
    let token =
      localStorage.getItem("userData") ?
      JSON.parse(localStorage.getItem("userData")).token : "";
    axios
      .post(Constants.APIURL + "v1/user/transferLawyerPayment", data, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        // console.log(response.status);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response && error.response.data) {
          swal("Error", error.response.data.responseMessage);
        }
      });
  }

  bookAppointment(data,updateLoader,cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    axios
      .post(Constants.APIURL + "v1/user/bookAppointment", data, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        // console.log(response.status);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response && error.response.data) {
          swal("Error", error.response.data.responseMessage).then(updateLoader());
          // swal({text:error.response.data.responseMessage, type:"Error"}).then(updateLoader());
        }
      });
  }

  bookOtherUserAppoitment(data, cb) {
    let token =
      localStorage.getItem("userData") ?
      JSON.parse(localStorage.getItem("userData")).token : "";
    axios
      .post(Constants.APIURL + "v1/otherUser/bookAppointment", data, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        // console.log(response.status);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response && error.response.data) {
          swal("Error", error.response.data.responseMessage);
        }
      });
  }
 

  viewFollowUp_Appointment(userId, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    let userType = localStorage.getItem("userType")
      ? localStorage.getItem("userType")
      : "";
    let user = "";
    if (userType === 1) {
      user = "lawyer";
    } else {
      user = "user";
    }
    let url = `v1/user/viewFollowUp_Appointment?bookingId=${userId}`;
    axios
      .get(Constants.APIURL + `${url}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  viewLawyerAppointment(userId, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    let userType = localStorage.getItem("userType")
      ? localStorage.getItem("userType")
      : "";
    let user = "";
    if (userType === 1) {
      user = "lawyer";
    } else {
      user = "user";
    }
    let url = `v1/${user}/viewAppointment?_id=${userId}`;
    axios
      .get(Constants.APIURL + `${url}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  rescheduleLawyerAppointment(data, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    let url = `v1/lawyer/rescheduleAppointment`;
    axios
      .post(Constants.APIURL + `${url}`, data, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  deleteCard(data, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .delete(Constants.APIURL + `v1/user/deleteCard?cardId=${data}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        console.log("zzzzzzzz", response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        console.log("dddddddddd", error);
        if (error.response && error.response.data) {
          swal("Error", error.response.data.responseMessage);
        }
      });
  }

  getLawyerFreshUpcomingAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/lawyer/upcomingAppointment", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }
  getLawyerFreshCompletedAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/lawyer/completedAppointment", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  // getLawyerFreshCompletedAppointment(cb) {
  //   let token = localStorage.getItem("token")
  //     ? localStorage.getItem("token")
  //     : "";
  //   axios
  //     .get(Constants.APIURL + "v1/lawyer/completedAppointment", {
  //       headers: {
  //         token: token,
  //       },
  //     })
  //     .then((response) => {
  //       cb(response);
  //     }, this)
  //     .catch((error)=>ToasterFunc("error", error))
  // }

  getLawyerFreshCancelledAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/lawyer/cancelledAppointments_list", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getUserFreshUpcomingAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/upcomingAppointment", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getUserFreshCompletedAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/completedAppointment", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getUserFreshCancelledAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/cancelledAppointments_list", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getLawyerUpcomingAppointmentDetails(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    let userId = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).userId
      : "";
    axios
      .get(Constants.APIURL + `v1/lawyer/viewAppointment?_id=${userId}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  moveAppointment(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .post(Constants.APIURL + "v1/lawyer/moveAppointment", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        console.log(response)
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  cancelAppointment(data, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    axios
      .put(
        Constants.APIURL +
          `v1/user/cancelAppointment?bookingId=${data.bookingId}`,
        data,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  legalDocument_share(payload, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    axios
      .post(Constants.APIURL + `v1/lawyer/legalDocument_share`, payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  cancelLawyerFollowUpAppointment(data, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    axios
      .put(
        Constants.APIURL +
          `v1/lawyer/cancelFollowUpAppointment?bookingId=${data.bookingId}`,
        data,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  rejectfollowUpBooking(data, cb) {
    let token =
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).token;
    axios
      .put(
        Constants.APIURL +
          `v1/user/rejectfollowUpBooking?bookingId=${data.bookingId}`,
        data,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  lawyerRating(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .post(Constants.APIURL + "v1/user/rating", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  followUp_appointmentRequest(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .post(
        Constants.APIURL + "v1/lawyer/followUp_appointmentRequest",
        payload,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  legalDocumentList(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    // let userId  = localStorage.getItem('userData') ? JSON.parse( localStorage.getItem('userData')).userId : ""
    axios
      .get(Constants.APIURL + `v1/user/legalDocumentList`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  //=============followUp appointment==============================
  getLawyerFollowUpcomingAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/lawyer/pendingFollowUp_appointmentList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getLawyerFollowCompletedAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/lawyer/completedFollowUp_appointmentList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getLawyerFollowCancelledAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/lawyer/cancelledFollowUp_appointmentList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }
  getLawyerFollowAcceptedAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/lawyer/acceptedFollowUp_appointmentList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }
  //==========================================================

  //============================client Followup===============

  getUserFollowUpcomingAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/newFollowUpList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getUserFollowCompletedAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/completedFollowUp_AppointmentList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getUserFollowCancelledAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/cancelledFollowUp_AppointmentList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getUserFollowAcceptedAppointment(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .get(Constants.APIURL + "v1/user/acceptedFollowUp_AppointmentList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }
  getLawyerProfile(cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
      console.log(token)
    axios
      .get(Constants.APIURL + "v1/lawyer/myProfile", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  updateLawyerAvailablity(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .post(Constants.APIURL + "v1/lawyer/updateAvailability", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  updateLawyerProfile(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .put(Constants.APIURL + "v1/lawyer/updateProfile", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  getTopLawyersList(cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    axios
      .get(Constants.APIURL + "v1/common/top_lawyers", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  contactUs(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .post(Constants.APIURL + "v1/common/contact_us", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  setNotification(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .post(Constants.APIURL + "v1/common/setNotification", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }
  getUserNotificationList(cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    axios
      .get(Constants.APIURL + "v1/user/notificationList", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.log(error));
  }
  acceptRescheduledBooking(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .put(
        Constants.APIURL + `v1/user/acceptBooking?bookingId=${payload}`,
        payload,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

    acceptMovedAppointment(payload,status, cb) {
        let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
        axios.put(Constants.APIURL + `v1/lawyer/acceptReject_moveRequest?bookingId=${payload}&status=${status}`,
            payload,
            {
                headers: {
                    'token': token
                }
            },
        )
            .then((response) => {
                cb(response);

            }, this)
            .catch((error) => console.error(error))
    }

    
  //============================================= Common API =================================

  getStatic(data, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    // 
    axios
      .get(
        Constants.APIURL +
          `v1/static/viewStaticContent?appType=${data.appType}&Type=${data.type}`,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }
  // getLawyerDetails(userId, cb) {
  //     let token = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).token : "";
  //     console.log("sadxxxxx", token)
  //     axios.get(Constants.APIURL + `v1/admin/viewLaywerProfile?_id=${userId}`,
  //         {
  //             headers: {
  //                 'token': token
  //             }
  //         })
  //         .then((response) => {
  //             cb(response);

  //         }, this)
  //         .catch((error) => alert(error))
  // }

  getLawyerNotification(cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    axios
      .get(Constants.APIURL + `v1/lawyer/notificationList`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.log(error));
  }

  getViewNotification(userId, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    axios
      .get(Constants.APIURL + `v1/common/viewNotification?_id=${userId}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.log(error));
  }

  getNotification(userId, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    // 
    axios
      .get(Constants.APIURL + `v1/common/viewNotification?_id=${userId}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  changePassword(data, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    // 
    axios
      .put(
        Constants.APIURL +
          `v1/common/changePassword?oldPassword=${data.oldPassword}&newPassword=${data.newPassword}&confirmPassword=${data.confirmPassword}`,
        data,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        cb(response);
      }, this)
       .catch((error)=>ToasterFunc("error", error))
  }

  acceptRejectMovedAppoitment(payload, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    axios
      .put(
        Constants.APIURL +
          `v1/lawyer/acceptReject_moveRequest?bookingId=${payload.bookingId}&status=${payload.status}`,
        null,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }
  acceptRejectMovedAppoitmentUser(payload, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    

    axios
      .put(
        Constants.APIURL +
          `v1/user/acceptReject_moveRequest?bookingId=${payload.bookingId}&status=${payload.status}`,
        null,
        {
          headers: {
            token: token,
          },
        }
      )
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getFaq(data, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    axios
      .get(Constants.APIURL + `v1/static/faqList?appType=${data}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  acceptFollowUp(payload, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .post(Constants.APIURL + "v1/user/acceptFollowUpBooking", payload, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }
  getUnavilableDateList(cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    axios
      .get(Constants.APIURL + `v1/lawyer/unavailableDateList`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  deleteUnavailableDate(id, cb) {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    axios
      .delete(Constants.APIURL + `v1/lawyer/deleteUnavailableDate/${id}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        if (response.data.responseCode === 200) {
          cb(response);
        } else {
          cb({ error: response.data.responseMessage });
        }
      }, this)
      .catch((error) => {
        if (error.response && error.response.data) {
          swal("Error", error.response.data.responseMessage);
        }
      });
  }

  getLaywerServicesList(cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    axios
      .get(Constants.APIURL + `v1/lawyer/myServiceList `, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }
  getLawyerServiceDetails(id, cb) {
    let token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : "";
    
    axios
      .get(Constants.APIURL + `v1/lawyer/getLegalDocument?serviceId=${id}`, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  checkOtherUserPayment(id, cb) {
    axios
      .get(Constants.APIURL + `v1/otherUser/checkPaymentStatus/${id}`, {})
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  getOtherAppointmentDetails(id, cb) {
    axios
      .get(Constants.APIURL + `v1/otherUser/getAppointmentDetails/${id}`, {})
      .then((response) => {
        cb(response);
      }, this)
      .catch((error)=>ToasterFunc("error", error))
  }

  paymentSuccess(payload, cb) {
    axios
      .post(Constants.APIURL + "v1/otherUser/payment", payload, {})
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }

  getTwilioTokenOtherUser(payload, cb) {
    axios
      .post(
        Constants.APIURL + `v1/call/getRoomAccessToken`,
        payload , {}
      )
      .then((response) => {
        cb(response);
      }, this)
      .catch((error) => console.error(error));
  }
}
