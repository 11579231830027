export const HOME = "/home";
export const SIGN_UP_AS = "/login-as";
export const SIGN_UP_AS_CLIENT = "/signup-as-a-client";
export const SIGN_UP_AS_OTHER = "/signup-as-a-other";
export const VERIFICATION_CODE = "/verification";
export const SIGN_IN = "/sign-in";
export const FORGOT_PASSWORD = "/forgot-password";
export const RECIEVE_EMAIL = "/recieve-email";
export const CREATE_NEW_PASSWORD = "/create-new-password";
export const MY_APPOINTMENTS = "/my-appointments";
export const LEGAL_DOCUMENTS = "/legal-documents/:serviceId";

export const LEGAL_DOCUMENTS_CLIENT = "/legal-documents";
export const CLIENT_TERMS_AND_CONDITIONS = "/client-terms-and-conditions";
export const AUTH_LAYOUT = "/auth";
export const USER_LAYOUT = "/user";
export const CLIENT_PROFILE = "/profile";
export const CLIENT_PRIVACY_POLICY = "/privacy-policy";
export const CHANGE_PASSWORD = "/change-password";
export const NOTIFICATIONS = "/notifications";
export const CLIENTNOTIFICATIONS = "/client-notifications";
export const MOVE_APPOINTMENT_DETAILS = "/moved-appointment-details";
export const RESCHEDULED_APPOINTMENT_DETAILS =
  "/rescheduled-appointment-details";
export const VIEW_LAWYER_UPCOMING_BOOKING_DETAILS =
  "/view-lawyer-upcoming-booking-details";
export const LAWYER_MOVE_APPOINTMENT_DETAILS =
  "/lawyer-moved-appointment-details";

export const CLIENT_RESCHEDULED_APPOINTMENT_DETAILS =
  "/client-rescheduled-appointment-details";
export const CLIENT_MOVE_APPOINTMENT_DETAILS =
  "/moved-client-appointment-details";

// Lawyer

export const PERSONAL = "/personal";
export const PROFFESIONAL = "/professional";
export const PAYMENT = "/payment";
export const PROFFESIONAL_AVAILABILITY  = "/ProfessionalAvailablity";
export const YOUR_AVAILABILITY = "/your-availability";
export const LAWYER_LIST = "/lawyer-list";
export const LAWYER_DETAIL = "/lawyers-detail";
export const LAWYER_TERM_AND_CONDITIONS = "/lawyer-terms-and-conditions";
export const BOOK_FOR_MY_SELF = "/book-for-my-self";
export const SELECT_PAYMENT_METHOD = "/select-payment-method";
export const ADD_NEW_CARD = "/add-new-card";
export const EDIT_CARD = "/edit-card";
export const PAYMENT_SUCCESSFUL = "/payment-successful";
export const PAYMENT_FAILED = "/payment-failed";
export const ABOUT_US = "/about-us";
export const CONTACT_US = "/contact-us";
export const FAQ = "/faq";
export const LEASE_AGREEMENT = "/lease-agreement";
export const LEASE_AGREEMENT_DOCUMENT = "/lease-agreement-document";
export const LAWYER_PROFLIE = "/lawyer-profile";
export const LAWYER_EDIT_PROFLIE = "/lawyer-edit-profile";
export const MY_PROFESSIONAL_DETAILS = "/my-professional-details";
export const EDIT_MY_PROFESSIONAL_DETAILS = "/edit-my-professional-details";
export const OVERALL_RATINGS = "/overall-ratings";
export const MY_PAYMENTS = "/my-payments";
export const ADD_NEW_PAYMENT_DETAILS = "/add-new-payment-details";
export const VIDEO_CALL = "/video-call/:data/:type";
export const AUDIO_CALL = "/audio-call";
export const OTHER_USER_PROFILE = "/other-profile";
export const OTHER_USER_PROFILE_UPDATE = "/other-profile-update";
export const CLIENT_LEGAL_DOCUMENT = "/client-legal-document";
export const LAWYER_SERVICE_LIST = "/lawyer-service-list";

// Other user after booking
export const OTHER_USER_WELCOME = "/other-user-welcome/:appoitmentId";
export const OTHER_USER_JOIN_CALL = "/other-user-join-call/:appoitmentId";
export const OTHER_USER_PAYMENT_SUCCESSFUL =
  "/other-user-payment-successful/:appoitmentId";
export const OTHER_USER_PAYMENT_FAILED =
  "/other-user-payment-failed/:appoitmentId";
export const OTHER_USER_VIDEO_CALL = "/other-user-video-call/:data/:type";
export const OTHER_USER_REJOIN_SESSION =
  "/other-user-rejoin-session/:appoitmentId";
export const OTHER_USER_WAIT_SESSION = "/other-user-wait-session/:appoitmentId";

export const Move_Appointment = "/move-appointment";
export const JOIN_CALL = "/call/:roomdata";
