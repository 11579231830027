import React, { Component, Suspense, lazy ,useEffect} from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import {
  SIGN_UP_AS,
  SIGN_UP_AS_CLIENT,
  SIGN_UP_AS_OTHER,
  HOME,
  VERIFICATION_CODE,
  SIGN_IN,
  FORGOT_PASSWORD,
  PERSONAL,
  PROFFESIONAL,
  PAYMENT,
  CREATE_NEW_PASSWORD,
  MY_APPOINTMENTS,
  LEGAL_DOCUMENTS,
  YOUR_AVAILABILITY,
  LAWYER_LIST,
  LAWYER_TERM_AND_CONDITIONS,
  CLIENT_TERMS_AND_CONDITIONS,
  LAWYER_DETAIL,
  BOOK_FOR_MY_SELF,
  SELECT_PAYMENT_METHOD,
  ADD_NEW_CARD,
  PAYMENT_SUCCESSFUL,
  PAYMENT_FAILED,
  RECIEVE_EMAIL,
  CLIENT_PROFILE,
  CLIENT_PRIVACY_POLICY,
  CHANGE_PASSWORD,
  NOTIFICATIONS,
  MOVE_APPOINTMENT_DETAILS,
  RESCHEDULED_APPOINTMENT_DETAILS,
  ABOUT_US,
  EDIT_CARD,
  CONTACT_US,
  FAQ,
  LEASE_AGREEMENT,
  LAWYER_PROFLIE,
  LAWYER_EDIT_PROFLIE,
  VIEW_LAWYER_UPCOMING_BOOKING_DETAILS,
  MY_PROFESSIONAL_DETAILS,
  EDIT_MY_PROFESSIONAL_DETAILS,
  OVERALL_RATINGS,
  MY_PAYMENTS,
  ADD_NEW_PAYMENT_DETAILS,
  OTHER_USER_WELCOME,
  OTHER_USER_JOIN_CALL,
  OTHER_USER_PAYMENT_SUCCESSFUL,
  OTHER_USER_PAYMENT_FAILED,
  VIDEO_CALL,
  AUDIO_CALL,
  OTHER_USER_VIDEO_CALL,
  OTHER_USER_REJOIN_SESSION,
  OTHER_USER_WAIT_SESSION,
  LEASE_AGREEMENT_DOCUMENT,
  OTHER_USER_PROFILE,
  OTHER_USER_PROFILE_UPDATE,
  CLIENT_LEGAL_DOCUMENT,
  Move_Appointment,
  LAWYER_MOVE_APPOINTMENT_DETAILS,
  CLIENTNOTIFICATIONS,
  CLIENT_RESCHEDULED_APPOINTMENT_DETAILS,
  CLIENT_MOVE_APPOINTMENT_DETAILS,
  LAWYER_SERVICE_LIST,
  LEGAL_DOCUMENTS_CLIENT,
  PROFFESIONAL_AVAILABILITY,
} from "./../config/RouterConfig";
import LawyerMovedAppointmentDetails from "../components/moved-appointment-details/MovedAppointmentDetails";
import GetDeviceToken from "../device-token/DeviceToken";
import PageNotFound from "../sharedComponnents/pageNotFound/PageNotFound";

const Home = lazy(() => import("../components/home/home"));
const SignUpAs = lazy(() =>
  import("./../sharedComponnents/sign-up-as/SignUpAs")
);
const SignUpAsAClient = lazy(() =>
  import("./../sharedComponnents/sign-up-as-client/SignUpAsAClient")
);
const SignUpAsAOther = lazy(() =>
  import("./../sharedComponnents/sign-up-as-other/SignUpAsAOther")
);
const VerificationCode = lazy(() =>
  import("./../sharedComponnents/verification-code/Verification")
);
const SignIn = lazy(() => import("./../sharedComponnents/sign-in/SignIn"));
const ForgotPassword = lazy(() =>
  import("./../sharedComponnents/forgot-password/ForgotPassword")
);
const recieveEmail = lazy(() =>
  import("./../sharedComponnents/recieveEmail/recieveEmail")
);
const CreateNewPassword = lazy(() =>
  import("./../sharedComponnents/create-new-password/CreateNewPassword")
);

const MyAppointments = lazy(() =>
  import("./../components/my-appointments/MyAppointments")
);
const LegalDocuments = lazy(() =>
  import("./../components/legal-documents/LegalDocuments")
);
const AddNewCard = lazy(() =>
  import("./../sharedComponnents/payment-method/add-new-card/AddNewCard")
);
const EditCard = lazy(() =>
  import("./../sharedComponnents/payment-method/add-new-card/EditCard")
);

const YourAvailability = lazy(() =>
  import("./../components/lawyer/your-availability/YourAvailability")
);
const Personal = lazy(() =>
  import("./../sharedComponnents/lawyer/signup/Personal")
);
const Proffesional = lazy(() =>
  import("./../sharedComponnents/lawyer/signup/Proffesional")
);
const Payment = lazy(() =>
  import("./../sharedComponnents/lawyer/signup/Payment")
);
const ProfessionalAvailablity = lazy(() =>
  import("./../sharedComponnents/availability-modal/AvailabilityModal2")
);
const LawyerList = lazy(() =>
  import("./../sharedComponnents/lawyer/LawyerList")
);
const LawyerTermsAndConditions = lazy(() =>
  import("./../components/lawyer/terms-and-conditions/LawyerTermsAndConditions")
);
const ClientTermAndConditions = lazy(() =>
  import("./../components/client/terms-and-conditions/ClientTermAndConditions")
);

const LawyersDetail = lazy(() =>
  import("../components/lawyers-detail/LawyersDetail")
);
const BookForMyself = lazy(() =>
  import("./../components/book-for-myself/BookForMyself")
);
const PaymentMethod = lazy(() =>
  import("./../sharedComponnents/payment-method/PaymentMethod")
);
// const AddNewCard = lazy(()=>import('./../sharedComponnents/payment-method/add-new-card/AddNewCard'));
const PaymentSuccessful = lazy(() =>
  import("./../sharedComponnents/payment-method/PaymentSuccessful")
);
const PaymentFailed = lazy(() =>
  import("./../sharedComponnents/payment-method/PaymentFailed")
);
const Profile = lazy(() => import("./../sharedComponnents/profile/Profile"));
const PrivacyPolicy = lazy(() =>
  import("../components/client/privacy-policy/PrivacyPolicy")
);
const ChangePassword = lazy(() =>
  import("../sharedComponnents/change-password/ChangePassword")
);
const Notifications = lazy(() =>
  import("../sharedComponnents/notifications/Notifications")
);
const ClientNotifications = lazy(() =>
  import("../components/client/notification/ClientNotification")
);
const MovedAppointmentDetails = lazy(() =>
  import("../components/moved-appointment-details/MovedAppointmentDetails")
);
const RescheduledAppointmentDetails = lazy(() =>
  import(
    "../components/rescheduled-appointment-details/RescheduledAppointmentDetails"
  )
);
const AboutUs = lazy(() => import("../components/lawyer/about-us/AboutUs"));
const MoveAppointment = lazy(() =>
  import("../components/lawyer/move-appointment/MoveAppointment")
);
const ContactUs = lazy(() =>
  import("../components/lawyer/contact-us/ContactUs")
);
const Faq = lazy(() => import("../components/lawyer/faq/Faq"));
const LeaseAgreement = lazy(() =>
  import("../components/lease-agreement/LeaseAgreement")
);
const LeaseAgreementDocument = lazy(() =>
  import("../components/lease-agreement-document/LeaseAgreementDocument")
);
const LawyerProfile = lazy(() =>
  import("../components/lawyer/lawyer-profile/LawyerProfile")
);
const LawyerEditProfile = lazy(() =>
  import("../components/lawyer/lawyer-profile/LawyerEditProfile")
);
const ViewlawyerUpcomingBooking = lazy(() =>
  import("../components/my-appointments/fresh-appointments/ViewBookingDetails")
);
const MyProfessionalDetails = lazy(() =>
  import("../components/lawyer/my-professional-details/MyProfessionalDetails")
);
const EditMyProfessionalDetails = lazy(() =>
  import(
    "../components/lawyer/my-professional-details/EditMyProfessionalDetails"
  )
);
const OverallRatings = lazy(() =>
  import("./../components/lawyer/overall-ratings/OverallRatings")
);
const MyPayments = lazy(() =>
  import("./../components/lawyer/my-payments/MyPayments")
);
const AddNewPaymentDetails = lazy(() =>
  import("./../components/lawyer/my-payments/AddNewPaymentDetails")
);
const VideoCall = lazy(() =>
  import("./../sharedComponnents/video-call/VideoCall")
);
const AudioCall = lazy(() =>
  import("./../sharedComponnents/audio-call/AudioCall")
);
const OtherUserProfile = lazy(() =>
  import("./../components/other/OtherUserProfile")
);
const OtherUserUpdateProfile = lazy(() =>
  import("./../components/other/OtherUserUpdateProfile")
);
const ClientLegalDocument = lazy(() =>
  import("./../components/client/legal-document/ClientLegalDocument")
);
const ClientMovedAppointmentDetails = lazy(() =>
  import(
    "./../components/client/client-moved-appointment-details/ClientMovedAppointmentDetails"
  )
);
const ClientRescheduledAppointmentDetails = lazy(() =>
  import(
    "./../components/client/clientrescheduled-appointment-details/ClientRescheduledAppointmentDetails"
  )
);

// Other user after booking
const OtherUserWelCome = lazy(() =>
  import("./../components/other-user-after-booking/OtherUserWelCome")
);
const OtherUserJoinCall = lazy(() =>
  import("./../components/other-user-after-booking/OtherUserJoinCall")
);
const OtherUserPaymentSuccessful = lazy(() =>
  import("./../components/other-user-after-booking/PaymentSuccessful")
);
const OtherUserPaymentFailed = lazy(() =>
  import("./../components/other-user-after-booking/PaymentFailed")
);
const OtherUserVideoAudioCall = lazy(() =>
  import("./../components/other-user-after-booking/OtherUserVideoAudioCall")
);
const OtherUserRejoinSession = lazy(() =>
  import("./../components/other-user-after-booking/OtherUserRejoinSession")
);
const OtherUserWaitingSession = lazy(() =>
  import("./../components/other-user-after-booking/OtherUserWaitingSession")
);
const ServiceList = lazy(() =>
  import("../components/lawyer/service-list/ServiceList")
);

const history = createBrowserHistory();
const RouterManagement = () => {

  useEffect(() => {
    var userAgent = window.navigator.userAgent;

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        // iPad or iPhone
        let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        if (!isSafari){
          // Anything else
          GetDeviceToken(history);

        }
        // else{
        //  alert("Sorry , Safari doesn't provide push notifications")
        // }
      }
      else {
        let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
         if (!isSafari){
           // Anything else
           GetDeviceToken(history);

         }
        //  else{
        //   alert("Sorry , Safari doesn't provide push notifications")
        //  }
      }
  }, [])
  
  return (
   <Router history={history} >
        <Suspense fallback={<div className="sl-loading">Loading...</div>}>
          <Routes>
            <Route  path="/" element={<Home/>} />
            <Route  path="/home" element={<Home/>} />
            <Route  path="/login-as" element={<SignUpAs />} />
            <Route  path={SIGN_UP_AS_CLIENT} element={<SignUpAsAClient/>} />
            <Route  path={SIGN_UP_AS_OTHER} element={<SignUpAsAOther/>} />
            <Route
              
              path={VERIFICATION_CODE}
              element={<VerificationCode/>}
            />
            <Route  path={SIGN_IN} element={<SignIn/>} />
            <Route  path={FORGOT_PASSWORD} element={<ForgotPassword/>} />
            <Route
              
              path={CREATE_NEW_PASSWORD}
              element={<CreateNewPassword/>}
            />
            <Route  path={MY_APPOINTMENTS} element={<MyAppointments/>} />
            <Route  path={LEGAL_DOCUMENTS} element={<LegalDocuments/>} />

            <Route
              
              path={LEGAL_DOCUMENTS_CLIENT}
              element={<LegalDocuments/>}
            />
            <Route
              
              path={CLIENT_TERMS_AND_CONDITIONS}
              element={<ClientTermAndConditions/>}
            />
            <Route  path={RECIEVE_EMAIL} element={<recieveEmail/>} />
            <Route  path={CLIENT_PROFILE} element={<Profile/>} />
            <Route
              
              path={CLIENT_PRIVACY_POLICY}
              element={<PrivacyPolicy/>}
            />
            <Route  path={CHANGE_PASSWORD} element={<ChangePassword/>} />
            <Route
              
              path={MOVE_APPOINTMENT_DETAILS}
              element={<MovedAppointmentDetails/>}
            />
            <Route  path={Move_Appointment} element={<MoveAppointment/>} />
            <Route
              
              path={VIEW_LAWYER_UPCOMING_BOOKING_DETAILS}
              element={<ViewlawyerUpcomingBooking/>}
            />
            <Route
              
              path={RESCHEDULED_APPOINTMENT_DETAILS}
              element={<RescheduledAppointmentDetails/>}
            />
            <Route
              
              path={LAWYER_MOVE_APPOINTMENT_DETAILS}
              element={<LawyerMovedAppointmentDetails/>}
            />
            <Route
              
              path={CLIENTNOTIFICATIONS}
              element={<ClientNotifications/>}
            />
            <Route
              
              path={CLIENT_MOVE_APPOINTMENT_DETAILS}
              element={<ClientMovedAppointmentDetails/>}
            />
            <Route
              
              path={CLIENT_RESCHEDULED_APPOINTMENT_DETAILS}
              element={<ClientRescheduledAppointmentDetails/>}
            />

            {/* lawyer */}
            <Route  path={PERSONAL} element={<Personal/>} />
            <Route  path={PROFFESIONAL} element={<Proffesional/>} />
            <Route  path={PAYMENT} element={<Payment/>} />
            <Route  path={PROFFESIONAL_AVAILABILITY} element={<ProfessionalAvailablity/>} />
            <Route
              
              path={YOUR_AVAILABILITY}
              element={<YourAvailability/>}
            />
            <Route  path={LAWYER_LIST} element={<LawyerList/>} />
            <Route  path={LAWYER_DETAIL} element={<LawyersDetail/>} />
            <Route
              
              path={LAWYER_TERM_AND_CONDITIONS}
              element={<LawyerTermsAndConditions/>}
            />
            <Route  path={BOOK_FOR_MY_SELF} element={<BookForMyself/>} />
            <Route
              
              path={SELECT_PAYMENT_METHOD}
              element={<PaymentMethod/>}
            />
            <Route  path={ADD_NEW_CARD} element={<AddNewCard/>} />
            <Route  path={EDIT_CARD} element={<EditCard/>} />
            <Route
              
              path={PAYMENT_SUCCESSFUL}
              element={<PaymentSuccessful/>}
            />
            <Route  path={PAYMENT_FAILED} element={<PaymentFailed/>} />
            <Route  path={NOTIFICATIONS} element={<Notifications/>} />
            <Route  path={ABOUT_US} element={<AboutUs/>} />
            <Route  path={CONTACT_US} element={<ContactUs/>} />
            <Route  path={FAQ} element={<Faq/>} />
            <Route  path={LEASE_AGREEMENT} element={<LeaseAgreement/>} />
            <Route
              
              path={LEASE_AGREEMENT_DOCUMENT}
              element={<LeaseAgreementDocument/>}
            />
            <Route  path={LAWYER_PROFLIE} element={<LawyerProfile/>} />
            <Route
              
              path={LAWYER_EDIT_PROFLIE}
              element={<LawyerEditProfile/>}
            />
            <Route
              
              path={MY_PROFESSIONAL_DETAILS}
              element={<MyProfessionalDetails/>}
            />
            <Route
              
              path={EDIT_MY_PROFESSIONAL_DETAILS}
              element={<EditMyProfessionalDetails/>}
            />
            <Route  path={OVERALL_RATINGS} element={<OverallRatings/>} />
            <Route  path={MY_PAYMENTS} element={<MyPayments/>} />
            <Route
              
              path={ADD_NEW_PAYMENT_DETAILS}
              element={<AddNewPaymentDetails/>}
            />
            <Route  path={VIDEO_CALL} element={<VideoCall/>} />
            <Route  path={AUDIO_CALL} element={<AudioCall/>} />
            <Route
              
              path={OTHER_USER_PROFILE}
              element={<OtherUserProfile/>}
            />
            <Route
              
              path={OTHER_USER_PROFILE_UPDATE}
              element={<OtherUserUpdateProfile/>}
            />
            <Route
              
              path={CLIENT_LEGAL_DOCUMENT}
              element={<ClientLegalDocument/>}
            />
            <Route  path={LAWYER_SERVICE_LIST} element={<ServiceList/>} />

            {/* Other user after booking */}
            <Route
              
              path={OTHER_USER_WELCOME}
              element={<OtherUserWelCome/>}
            />
            <Route
              
              path={OTHER_USER_JOIN_CALL}
              element={<OtherUserJoinCall/>}
            />
            <Route
              
              path={OTHER_USER_PAYMENT_SUCCESSFUL}
              element={<OtherUserPaymentSuccessful/>}
            />
            <Route
              
              path={OTHER_USER_PAYMENT_FAILED}
              element={<OtherUserPaymentFailed/>}
            />
            <Route
              
              path={OTHER_USER_VIDEO_CALL}
              element={<OtherUserVideoAudioCall/>}
            />
            <Route
              
              path={OTHER_USER_REJOIN_SESSION}
              element={<OtherUserRejoinSession/>}
            />
            <Route
              
              path={OTHER_USER_WAIT_SESSION}
              element={<OtherUserWaitingSession/>}
            />
            <Route path="/*" element={<PageNotFound />} />
            
          </Routes>
        </Suspense>
      </Router>
  )
}

export default RouterManagement
