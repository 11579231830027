import { URL , LOGIN, LOGOUT, SOCIAL_LOGIN,USER_DATA } from '../Action/ActionType';

const initialState = {
    user: {},
    token: localStorage.getItem('token') ? localStorage.getItem('token') : "" ,
    userType: localStorage.getItem('userType') ?  localStorage.getItem('userType') : "",
    userId : localStorage.getItem('userId') ? localStorage.getItem('userId') : "",
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN: return {
            ...state,
            token : action.payload.token,
            userType : action.payload.userType,
            userId : action.payload.userId,
            user : action.payload.user,
        }
        case  SOCIAL_LOGIN: return {
            ...state,
            token : action.payload.token,
            userType : action.payload.userType,
            userId : action.payload.userId
        }
        case  USER_DATA: return {
            ...state,
            user : action.payload.user,
        }
        case LOGOUT: return initialState;
        default: return initialState;
    }
}

export default AuthReducer;