import React from 'react'
import './style.scss';
import { ReactComponent as OopsIc } from '../../assets/img/oops.svg';
import { Link } from "react-router-dom";
const PageNotFound = () => {
    return (
        <div className="page-not-found mt100">

            <div className="container">
                <div className="cn-sub-page">
                    <div className="not-found">

                        <span className="cn-icon"><OopsIc /></span>
                        <h3>Page Not Found</h3>

                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut mi sed nisi viverra blandit. Pellentesque ac vestibulum diam..</p> */}


                        <Link to="/" className="goToHome">Go to Home</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PageNotFound