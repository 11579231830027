import React, { useState } from 'react';
import './modal-style.scss';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const RefundAndAnotherOption = (props) => {

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div className="refun_modal">
      <a onClick={toggle}>modal</a>
      <Modal isOpen={modal} toggle={toggle} className="refun_dialog_modal modal-dialog-centered sl-modal">
        <ModalBody>
            <div className="modal_content">
            <button className="sl-blue-btn">I Want Refund</button>
            <button className="sl-blue-btn">Choose Another Lawyer</button>
          <p>You can choose another lawyer. The amount will be adjusted accordingly.</p>
            </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RefundAndAnotherOption;