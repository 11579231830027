import React, { useEffect, useState } from "react";
import "./style.scss";
import Footer from "../../sharedComponnents/footer/Footer";
import Header from "../../sharedComponnents/header/Header";
import { ReactComponent as ExpIc } from "../../assets/img/exp.svg";
import { ReactComponent as RankIc } from "../../assets/img/rank.svg";
import { ReactComponent as CortIc } from "../../assets/img/cort.svg";
import { ReactComponent as PdfIc } from "../../assets/img/pdf.svg";
import RefundAndAnotherOption from "./RefundAndAnotherOptionModal";
import Users from "../../Services/users";
import { NOTIFICATIONS } from "../../config/RouterConfig";
import { useNavigate } from "react-router-dom";

function LawyerMovedAppointmentDetails(props) {
  const appointmentIdAndMsg = props.location.state;
  const history = useNavigate();
  const user = new Users();
  const [lawyerDetails, setLawyerDetails] = useState([]);
  const [bookingDate, setBookingDate] = useState("");

  console.log("======  >>", props.location);

  useEffect(() => {
    getLawyerDetails();
  }, []);

  const getLawyerDetails = () => {
    user.viewLawyerAppointment(appointmentIdAndMsg.appointmentId, (res) => {
      if (res.status === 200) {
        console.log(res.data);
        let lawyerData = res.data.appointmentDetails;
        let bookingDate = new Date(lawyerData.bookingDate).toLocaleString();
        console.log(bookingDate);
        setLawyerDetails(lawyerData);
        setBookingDate(bookingDate);
        console.log("lawyerData", lawyerData);
      }
    });
  };

  const handleAcceptRejectRequest = (payload) => {
    user.acceptRejectMovedAppoitment(payload, (res) => {
      console.log(res);
      history(NOTIFICATIONS);
    });
  };

  const handleAccept = () => {
    console.log(lawyerDetails?._id);
    const payload = {
      bookingId: lawyerDetails?._id,
      status: "accept",
    };
    handleAcceptRejectRequest(payload);
  };

  const handleReject = () => {
    console.log(lawyerDetails?._id);
    const payload = {
      bookingId: lawyerDetails?._id,
      status: "reject",
    };
    handleAcceptRejectRequest(payload);
  };

  return (
    <div className="movedAppointDetail">
      <Header />
      <div className="container">
        <div className="sl-main-box">
          <div className="sl-main-heading">
            <h3>Moved Appointment Details</h3>
          </div>
          <div className="appoint_Detail_box">
            <p>{appointmentIdAndMsg.notificationMessage}</p>
            {/* <p>Due to the non-availability, your appointment booked with Lawyer Cameron is moved to a new Lawyer John on same date and time. See details and Accept/Reject the moved appointment.</p> */}
            <div className="flex jas time-flex-box">
              <div className="flex-item">
                <label htmlFor="">Date and Time</label>
                <p>{bookingDate}</p>
              </div>
              <div className="flex-item">
                <div className="ar-buttons">
                  <button className="accept-btn" onClick={handleAccept}>
                    ACCEPT
                  </button>
                  <button className="reject-btn" onClick={handleReject}>
                    REJECT
                  </button>
                  {/* need to disscuss  <RefundAndAnotherOption/> */}
                </div>
              </div>
            </div>
          </div>
          <div className="view_booking_details">
            <div className="lawyer__info">
              <div className="view_box">
                <div className="flex jas heading">
                  <div className="item">
                    <h3>New Lawyer Details</h3>
                  </div>
                </div>
              </div>
              <div className="view_box view_detail_box">
                <div className="book_box">
                  <div className="flex">
                    <div className="item">
                      <div className="lawyer_pic">
                        <img
                          src={
                            lawyerDetails &&
                              lawyerDetails.lawyerId &&
                              lawyerDetails.lawyerId.profilePic
                              ? lawyerDetails.lawyerId.profilePic
                              : "/static/media/user.9f5239b9.svg"
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="item">
                      <h4>
                        {lawyerDetails &&
                          lawyerDetails.lawyerId &&
                          lawyerDetails.lawyerId.firstName}{" "}
                        {lawyerDetails &&
                          lawyerDetails.lawyerId &&
                          lawyerDetails.lawyerId.lastName}
                      </h4>
                      <span>{bookingDate}</span>
                      <div className="exp_box">
                        <p>
                          <span className="sl-icon">
                            <ExpIc />
                          </span>{" "}
                          {lawyerDetails &&
                            lawyerDetails.lawyerId &&
                            lawyerDetails.lawyerId.experience_in_years}{" "}
                          years{" "}
                          <span className="sl-icon">
                            <RankIc />
                          </span>{" "}
                          Rank{" "}
                          {lawyerDetails &&
                            lawyerDetails.lawyerId &&
                            lawyerDetails.lawyerId.rank}
                        </p>
                        <p>
                          <span className="sl-icon">
                            <CortIc />
                          </span>{" "}
                          {lawyerDetails &&
                            lawyerDetails.lawyerId &&
                            lawyerDetails.lawyerId.firmName}
                        </p>
                      </div>
                      <p>
                        {lawyerDetails &&
                          lawyerDetails.serviceId &&
                          lawyerDetails.serviceId.serviceName}
                      </p>
                    </div>
                    <div className="item">
                      <button className="sl-btn- sl-price-btn">
                        ${lawyerDetails && lawyerDetails.amount}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lawyer__info">
              <div className="view_box">
                <div className="flex jas heading">
                  <div className="item">
                    <h3>Previous Lawyer Details</h3>
                  </div>
                </div>
              </div>
              <div className="view_box view_detail_box">
                <div className="book_box">
                  <div className="flex">
                    <div className="item">
                      <div className="lawyer_pic">
                        <img
                          src={
                            lawyerDetails &&
                              lawyerDetails.oldLawyerId &&
                              lawyerDetails.oldLawyerId.profilePic
                              ? lawyerDetails.oldLawyerId.profilePic
                              : "/static/media/user.9f5239b9.svg"
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="item">
                      <h4>
                        {lawyerDetails &&
                          lawyerDetails.oldLawyerId &&
                          lawyerDetails.oldLawyerId.firstName}{" "}
                        {lawyerDetails &&
                          lawyerDetails.oldLawyerId &&
                          lawyerDetails.oldLawyerId.lastName}
                      </h4>
                      <span>{bookingDate}</span>
                      <div className="exp_box">
                        <p>
                          <span className="sl-icon">
                            <ExpIc />
                          </span>{" "}
                          {lawyerDetails &&
                            lawyerDetails.oldLawyerId &&
                            lawyerDetails.oldLawyerId.experience_in_years}{" "}
                          years{" "}
                          <span className="sl-icon">
                            <RankIc />
                          </span>{" "}
                          Rank{" "}
                          {lawyerDetails &&
                            lawyerDetails.oldLawyerId &&
                            lawyerDetails.oldLawyerId.rank}
                        </p>
                        <p>
                          <span className="sl-icon">
                            <CortIc />
                          </span>{" "}
                          {lawyerDetails &&
                            lawyerDetails.oldLawyerId &&
                            lawyerDetails.oldLawyerId.firmName}
                        </p>
                      </div>
                      <p>
                        {lawyerDetails &&
                          lawyerDetails.serviceId &&
                          lawyerDetails.serviceId.serviceName}
                      </p>
                    </div>
                    <div className="item">
                      <button className="sl-btn- sl-price-btn">
                        ${lawyerDetails && lawyerDetails.amount}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="follow-UpFee">
                           <div className="followUp-info">
                            <label htmlFor="">Follow-Up Fee</label>
                           <p>$120.00</p>
                           </div>
                           <div className="followUp-info">
                            <label htmlFor="">Message</label>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        <div className="flex">
                            <div className="atteched">
                                <label htmlFor="">Attachment</label>
                            </div>
                            <div className="doc-name"><span className="sl-icon"><PdfIc /></span><p>Documents <br/>Name</p></div>
                        </div>
                        </div> */}

            <div className="client-details">
              <h3>Client Details</h3>
              <div className="flex">
                <div className="item">
                  <div className="flex client_info">
                    <div className="item">
                      <label htmlFor="">Name</label>
                      <p>{lawyerDetails && lawyerDetails.name}</p>
                    </div>
                    <div className="item">
                      <label htmlFor="">Mobile Number</label>
                      <p>
                        {lawyerDetails && lawyerDetails.countryCode}
                        {lawyerDetails && lawyerDetails.mobileNumber}
                      </p>
                    </div>
                    <div className="item email_item">
                      <label htmlFor="">Email</label>
                      <p>{lawyerDetails && lawyerDetails.email}</p>
                    </div>
                  </div>
                  <div className="client_info">
                    <label htmlFor="">Message</label>
                    <p>{lawyerDetails && lawyerDetails.message}</p>
                  </div>
                </div>
                <div className="item">
                  <a href="" className="fresh-appt">
                    Fresh Appt
                  </a>
                </div>
              </div>
              <div className="rele_Doc">
                <h3>Relevant Documents</h3>
                <div className="all_rele_doc">
                  <div className="flex">
                    {lawyerDetails &&
                      lawyerDetails.document &&
                      lawyerDetails.document.map((doc) => (
                        <a href={doc.url}>
                          <div className="doc-name">
                            <span className="sl-icon">
                              <PdfIc />
                            </span>
                            <p>{doc.name}</p>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LawyerMovedAppointmentDetails;
