import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
// import LogoImg from "../../assets/img/logo-whitenew.png";
import LogoImg from "../../assets/img/SL-LogoNew.png";
// import LogoImg from "../../assets/img/logo-white.png";
import "./header.scss";
import { ReactComponent as SearchIc } from "../../assets/img/search.svg";
import { ReactComponent as NotifIc } from "../../assets/img/notification.svg";
import { ReactComponent as MenuIc } from "../../assets/img/menu.svg";
import { ReactComponent as MyApptIc } from "../../assets/img/my-appt.svg";
import { ReactComponent as PymtIc } from "../../assets/img/payments.svg";
import { ReactComponent as ProfDetalsIc } from "../../assets/img/prof-detail.svg";
import { ReactComponent as LegalDocIc } from "../../assets/img/legal-doc.svg";
import { ReactComponent as PolicyIc } from "../../assets/img/privacy-policy.svg";
import { ReactComponent as FaqsIc } from "../../assets/img/faqs.svg";
import { ReactComponent as TAndCIc } from "../../assets/img/t-and-c.svg";
import { ReactComponent as NftIc } from "../../assets/img/notification-fill.svg";
import { ReactComponent as ChgPwdIc } from "../../assets/img/change-password.svg";
import { ReactComponent as LogOutIc } from "../../assets/img/logout.svg";
import firebase from "firebase/app";
import { Document, Page, pdfjs } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


import {
  CLIENT_PROFILE,
  LAWYER_PROFLIE,
  LAWYER_TERM_AND_CONDITIONS,
  CLIENT_TERMS_AND_CONDITIONS,
  CLIENT_PRIVACY_POLICY,
  FAQ,
  NOTIFICATIONS,
  CHANGE_PASSWORD,
  MY_PROFESSIONAL_DETAILS,
  SELECT_PAYMENT_METHOD,
  CLIENTNOTIFICATIONS,
} from "../../config/RouterConfig";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Users from "../../Services/users";
import ToasterFunc from "../toastr/Toastr";
import SpinnerLoader from "../spinner/Spinner";
import swal from "sweetalert";
import { BehaviorSubject } from "rxjs";
import { USER_DATA } from "../../redux/Action/ActionType";
const lawyerPayment = {
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer"
}
export const deveiceToken = new BehaviorSubject(``);
function Header(props) {
  const { onSearchChange } = props;

  const dispatch = useDispatch();
  const user = new Users();
  const navigate = useNavigate();
  const [UserData, setUserData] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [userId, setUserId] = useState();
  const [IsLoader, setIsLoader] = useState(false);
  const [lawyerNotiListCount, setlawyerNotiListCount] = useState(false);
  const [clientNotiListCount, setclientNotiListCount] = useState(false);
  const [showSearch, setShowSearch] = useState(false)
  const authReducer = useSelector((state) => state.AuthReducer);
  const url = window.location.pathname;
  useEffect(() => {
    setUserData(authReducer.user);
    setUserId(authReducer.user._id);
    if (authReducer.userType === 1) {
      setShowSearch(false)
    }
    else {
      setShowSearch(true)
    }
  }, [authReducer.user])


  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari && 'safari' in window && 'pushNotification' in window.safari) {
    const permissionData = window.safari.pushNotification.permission('https://app.swiftlaw.com.au/');
    const permissionGranted = permissionData.permission === 'granted';

    if (!permissionGranted) {
      // window.safari.pushNotification.requestPermission(
      //   'https://app.swiftlaw.com.au/BFnIpSyz5qmlDWsa8Itz5Cp7rEEbi-b3Cj4x6pI11_NFn6rA_J7Se2KRF4BazJM60vQ3FWjNf-xpZ7wuRyYguV8',
      //   'https://app.swiftlaw.com.au/',
      //   {},
      //   handlePermission
      // );
    }
  }



  function handlePermission(permissionData) {
    if (permissionData.permission === 'granted') {
      // Handle permission granted
      const token = permissionData.deviceToken;
      deveiceToken.next(token);
      // Send the token to your server to associate it with the user
    } else if (permissionData.permission === 'denied') {
      // Handle permission denied
    } else if (permissionData.permission === 'default') {
      // Handle permission closed without selection
    }
  }

  let FirebaseMessaging = null;

  if (!isSafari) {
    FirebaseMessaging = firebase.messaging();

    FirebaseMessaging.onMessage((msg) => {
      console.log(
        "@@@@@1234555",
        `/video-call/${JSON.stringify(msg?.data)}/${msg?.data?.pushType}`
      );

      if (msg?.data?.twilioToken) {
        const data = JSON.stringify({
          receiverId: msg?.data?.receiverId,
          twilioToken: msg?.data?.twilioToken,
          room: msg?.data?.room,
          firstName: msg?.data?.firstName,
          lastName: msg?.data?.lastName,
        });
        localStorage.setItem("callerDetails", JSON.stringify(msg.data));
        window.open(
          `https://app.swiftlaw.com.au/video-call/${data}/${msg.data.pushType}`,
          "popUpWindow",
          "height=1000,width=1500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes"
        );
      } else if (msg?.data?.type === "call_reject") {
        // console.log("msg?.data?.type ", msg?.data?.type);
        navigate("/my-appointments");
      }
    });
  }


  const logout = () => {
    console.log("logout function called");
    let rememberMe = localStorage.getItem("rememberMe") ? localStorage.getItem("rememberMe") : "";
    let userData = localStorage.getItem("userData") ? localStorage.getItem("userData") : "";
    let email = localStorage.getItem("email") ? localStorage.getItem("email") : "";
    let password = localStorage.getItem("password") ? localStorage.getItem("password") : "";

    localStorage.clear();
    if (rememberMe) {
      localStorage.setItem("email", email ? email : "");
      localStorage.setItem("password", password ? password : "");
      localStorage.setItem("rememberMe", rememberMe ? rememberMe : "");
      localStorage.setItem("userData", userData ? userData : "");

    }


    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("userId");
    window.location.replace("/");
    // window.location.reload();
  };
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSidebar = () => {
    setSideBar(!sideBar);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (!Object.keys(authReducer.user).length) {
        getProfile();
      }
      getNotification();
    } else {
      navigate("/login-as")
    }
    // history.push("/my-appointments");
  }, []);


  const viewLawyerPayment = () => {
    const payload = {
      "accId": UserData?.connectAccountId
    }
    console.log("function called");
    console.log("function payload", payload);

    if (UserData?.connectAccountId) {
      user.connectLoginDashboard(payload, (res) => {
        if (res) {
          let url = res.data.url;
          window.open(url, "_blank");
        }
      })
    } else {
      swal("Error", "You don't have stripe connected account, pls create first.", "error");
      // alert("You don't have stripe connected account, pls create first.")
    }

  }

  const onTermClick = () => {
    window.open(`${LAWYER_TERM_AND_CONDITIONS}`);
  }

  const getProfile = () => {
    setIsLoader(true)
    if (authReducer.userType == 1) {
      user.getLawyerProfile((res) => {
        if (res.data.responseCode === 200) {
          setShowSearch(false)
          setIsLoader(false)
          dispatch({
            type: USER_DATA,
            payload: { user: res.data.userData }
          })
          setUserData(res.data.userData);
          setUserId(res.data.userData._id);
        } else {
        }
      });
    } else if (authReducer.userType == 2) {
      user.getProfile((res) => {
        if (res.data.responseCode === 200) {
          setShowSearch(true)
          setIsLoader(false);
          setUserData(res.data.userData);
          setUserId(res.data.userData._id);
          dispatch({
            type: USER_DATA,
            payload: { user: res.data.userData }
          })
        } else {
        }
      });
    } else {
      user.getProfile((res) => {
        if (res.data.responseCode === 200) {
          setShowSearch(true)
          setIsLoader(false);
          setUserData(res.data.userData);
          setUserId(res.data.userData._id);
          dispatch({
            type: USER_DATA,
            payload: { user: res.data.userData }
          })
        } else {
        }
      });
    }
  };

  // get notigication define here 

  const getNotification = () => {
    if (authReducer.userType == 1) {
      user.getLawyerNotification((res) => {
        if (res.data.responseCode === 200) {
          let LawyerNotiCOunt =
            res.data &&
            res.data.notificationList &&
            res.data.notificationList.filter((item) => item.isRead === false)
              .length;
          if (!!LawyerNotiCOunt) {
            setlawyerNotiListCount(true);
          }
        } else {
          swal("Error", res.error, "error");
        }
      });
    } else if (authReducer.userType == 2) {

      user.getUserNotificationList((res) => {
        if (res.data.responseCode === 200) {
          let ClientNotiCOunt =
            res.data &&
            res.data.notificationList &&
            res.data.notificationList.filter((item) => item.isRead === false)
              .length;
          if (!!ClientNotiCOunt) {
            setclientNotiListCount(true);
          }
        } else {
          swal("Error", res.error, "error");
        }
      });
    }
  };


  // notification status api is define here

  const notificationStatus = (event) => {
    setIsLoader(true);
    let obj = {
      notificationStatus: event,
    };

    user.setNotification(obj, (res) => {
      if (res.data.responseCode === 200) {
        setIsLoader(false);
        if (obj.notificationStatus === true) {
          ToasterFunc("success", "Notification Enabled");
        } else if (obj.notificationStatus === false) {
          ToasterFunc("success", "Notification Disabled");
        }
      } else {
        ToasterFunc("error", res.error);
      }
    });
  };
  // if (!isSafari) {
  //   FirebaseMessaging.onMessage((msg) => {
  //     console.log(
  //       "@@@@@1234555",
  //       `/video-call/${JSON.stringify(msg?.data)}/${msg?.data?.pushType}`
  //     );
  //     if (msg?.data?.twilioToken) {
  //       const data = JSON.stringify({
  //         receiverId: msg?.data?.receiverId,
  //         twilioToken: msg?.data?.twilioToken,
  //         room: msg?.data?.room,
  //         firstName: msg?.data?.firstName,
  //         lastName: msg?.data?.lastName,
  //       });
  //       localStorage.setItem("callerDetails", JSON.stringify(msg.data));
  //       window.open(
  //         `https://app.swiftlaw.com.au/video-call/${data}/${msg.data.pushType}`,
  //         "popUpWindow",
  //         "height=1000,width=1500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
  //       );
  //       // window.open(
  //       //   `https://swiftlaw.com.au/video-call/${data}/${msg?.data?.pushType}`,
  //       //   // `https://swiftlaw.com.au/video-call/${data}/${msg.data.pushType}`,
  //       //   "popUpWindow",
  //       //   "height=1000,width=1500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
  //       // );
  //       // history.push(`/video-call/${data}/${msg.data.pushType}`);
  //     } else if (msg?.data?.type === "call_reject") {
  //       console.log("msg?.data?.type ", msg?.data?.type);
  //       navigate("/my-appointments");
  //     }
  //   })
  // }


  return (
    <div className="header">
      {IsLoader && <SpinnerLoader />}
      <div className="container">
        <div className="flex jas main-flex">
          <div className="item menu_box">
            <div className="flex">
              <div className="toggle_menu">
                <span className="sl-icon" onClick={handleSidebar}>
                  <MenuIc />
                </span>
              </div>
              <Link to="/" className="brand">
                <img src={LogoImg} className="img-fluid" alt="" style={{ width: "220px" }} />
              </Link>
              <div className="sl-menu">
                {authReducer &&
                  authReducer.userType &&
                  authReducer.userType == 1 ? (
                  <>
                    <Link
                      to="/my-appointments"
                      className={url === "/my-appointments" ? "active" : null}
                    >
                      Appointments
                    </Link>
                    <Link
                      to={{
                        pathname: "/your-availability",
                        UserData: UserData,
                      }}
                      className={url === "/your-availability" ? "active" : null}
                    >
                      Availability
                    </Link>
                    <Link
                      to="/lawyer-service-list"
                      className={
                        url === "/legal-documents-list" ? "active" : null
                      }
                    >
                      Legal Docs
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="/my-appointments"
                      className={url === "/my-appointments" ? "active" : null}
                    >
                      My Appointments
                    </Link>
                    <Link
                      to="/legal-documents"
                      className={url === "/legal-documents" ? "active" : null}
                    >
                      Legal Documents
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="item">
            <div className="flex search-flex">
              {showSearch && (url === "/" || url === "/home") && <div className="item search-item">
                <div className="search_box">
                  <div className="flex">
                    <div className="sl-icon">
                      <SearchIc />
                    </div>
                    <input
                      onChange={onSearchChange}
                      type="text"
                      className="form-control"
                      placeholder="Search for a lawyer or service"
                    />
                  </div>
                </div>
              </div>}
              <div className="item notficationBox">
                <div className="flex">
                  <div className="notification">
                    {authReducer &&
                      authReducer.userType &&
                      authReducer.userType == 1 ? (
                      <Link
                        to={{ pathname: `${NOTIFICATIONS}`, state: userId }}
                      >
                        <span
                          className={
                            lawyerNotiListCount ? "sl-icon blink" : "sl-icon"
                          }
                        >
                          <NotifIc />
                        </span>
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: `${CLIENTNOTIFICATIONS}`,
                          state: userId,
                        }}
                      >
                        <span
                          className={
                            clientNotiListCount ? "sl-icon blink" : "sl-icon"
                          }
                        >
                          <NotifIc />
                        </span>
                      </Link>
                    )}
                  </div>
                  <div className="profile">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret>
                        <div className="avtar">
                          <img
                            src={
                              UserData?.profilePic ||
                              "/static/media/user.9f5239b9.svg"
                            }
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        {authReducer &&
                          authReducer.userType &&
                          authReducer.userType == 1 ? (
                          <>
                            <Link to={`${LAWYER_PROFLIE}`}>
                              <span className="sl-icon">
                                <MyApptIc />
                              </span>{" "}
                              My Profile
                            </Link>
                            <Link to={`${MY_PROFESSIONAL_DETAILS}`}>
                              <span className="sl-icon">
                                <ProfDetalsIc />
                              </span>{" "}
                              Professional Details
                            </Link>
                            <Link onClick={viewLawyerPayment} style={{ color: "#0089CC" }} >
                              <span className="sl-icon">
                                <ProfDetalsIc />
                              </span>{" "}
                              Payments
                            </Link>
                            {/* onClick = {viewLawyerPayment}  */}

                            {/* <Link to="/payment"><span className="sl-icon">
                                                            <PymtIc /></span> Payments 
                                                        </Link> */}
                          </>
                        ) : (
                          <>
                            <Link to={`${CLIENT_PROFILE}`}>
                              <span className="sl-icon">
                                <MyApptIc />
                              </span>{" "}
                              My Profile
                            </Link>
                            {authReducer.userType == 2 && (
                              <Link to={`${SELECT_PAYMENT_METHOD}`}>
                                <span className="sl-icon">
                                  <PymtIc />
                                </span>{" "}
                                Payments
                              </Link>
                            )}

                          </>
                        )}

                        <Link to={`${CLIENT_PRIVACY_POLICY}`}>
                          <span className="sl-icon">
                            <PolicyIc />
                          </span>{" "}
                          Privacy Policy
                        </Link>
                        <Link to={`${FAQ}`}>
                          <span className="sl-icon">
                            <FaqsIc />
                          </span>{" "}
                          FAQ's
                        </Link>
                        {authReducer &&
                          authReducer.userType &&
                          authReducer.userType == 1 ? (
                          <Link to={`${LAWYER_TERM_AND_CONDITIONS}`}>
                            <span className="sl-icon">
                              <TAndCIc />
                            </span>{" "}
                            Terms & Conditions
                          </Link>
                        ) : (
                          <Link to={`${CLIENT_TERMS_AND_CONDITIONS}`}>
                            <span className="sl-icon">
                              <TAndCIc />
                            </span>{" "}
                            Terms & Conditions
                          </Link>
                        )}

                        {authReducer &&
                          authReducer.userType &&
                          authReducer.userType == 1 ? (
                          <div className="noti_box">
                            <Link
                              to={{
                                pathname: `${NOTIFICATIONS}`,
                                state: userId,
                              }}
                            >
                              <span className="sl-icon">
                                <NftIc />
                              </span>{" "}
                              Notifications
                            </Link>
                            <div className="noti">
                              <label className="switch sl-switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    notificationStatus(e.target.checked)
                                  }
                                  name={"clientStatus"}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div className="noti_box">
                            <Link
                              to={{
                                pathname: `${CLIENTNOTIFICATIONS}`,
                                state: userId,
                              }}
                            >
                              <span className="sl-icon">
                                <NftIc />
                              </span>{" "}
                              Notifications
                            </Link>
                            <div className="noti">
                              <label className="switch sl-switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    notificationStatus(e.target.checked)
                                  }
                                  name={"clientStatus"}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        )}

                        <Link to={`${CHANGE_PASSWORD}`}>
                          <span className="sl-icon">
                            <ChgPwdIc />
                          </span>{" "}
                          Change Password
                        </Link>
                        <Link onClick={() => logout()}>
                          <span className="sl-icon">
                            <LogOutIc />
                          </span>{" "}
                          Logout
                        </Link>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sidebar-menu"
        style={sideBar ? { transform: "translateX(0)" } : null}
      >
        <div className="sidebar-profile">
          <div className="pro_pic">
            <img
              src={UserData && UserData.profilePic && UserData.profilePic}
              className="img-fluid"
              alt=""
            />
          </div>
          <h4>
            {UserData && UserData.firstName && UserData.firstName}{" "}
            {UserData && UserData.lastName && UserData.lastName}
          </h4>
          <p>
            {UserData && UserData.countryCode ? UserData.countryCode : "--"}{" "}
            {UserData && UserData.mobileNumber ? UserData.mobileNumber : "--"}
          </p>
          <p>{UserData && UserData.email && UserData.email}</p>
        </div>
        <div className="side_navbar">
          <Link to="/my-appointments">
            {" "}
            <span className="sl-icon">
              <MyApptIc />
            </span>{" "}
            My Appointments
          </Link>
          <Link to={`${SELECT_PAYMENT_METHOD}`}>
            {" "}
            <span className="sl-icon">
              <PymtIc />
            </span>{" "}
            Payments
          </Link>
          <Link to="/legal-documents">
            {" "}
            <span className="sl-icon">
              <LegalDocIc />
            </span>{" "}
            Legal Documents
          </Link>
          <Link to="/privacy-policy" >
            {" "}
            <span className="sl-icon">
              <PolicyIc />
            </span>{" "}
            Privacy Policy
          </Link>
          <Link to="/faq">
            {" "}
            <span className="sl-icon">
              <FaqsIc />
            </span>{" "}
            FAQ's
          </Link>
          <Link to="/lawyer-terms-and-conditions">
            {" "}
            <span className="sl-icon">
              <TAndCIc />
            </span>{" "}
            Terms & Conditions
          </Link>
          <Link to="/notifications">
            {" "}
            <span className="sl-icon">
              <NftIc />
            </span>{" "}
            Notifications
          </Link>
          <Link to={`${CHANGE_PASSWORD}`}>
            {" "}
            <span className="sl-icon">
              <ChgPwdIc />
            </span>{" "}
            Change Password
          </Link>
          <Link onClick={() => logout()}>
            {" "}
            <span className="sl-icon">
              <LogOutIc />
            </span>{" "}
            Logout
          </Link>
        </div>
      </div>
      {/* <div style={lawyerPayment}  onClick = {viewLawyerPayment} className = "mx-4 pt-2">
            <span className="text-light">
                Payments 
            </span>
        </div> */}
      <div
        onClick={handleSidebar.bind(this)}
        className={`overlay ${sideBar ? "open" : ""}`}
      ></div>
    </div>
  );
}

export default Header;
